<template>
  <div>
  	<div class="title">
				<div class="title_icon" v-show="hasIcon">						
				</div>
				{{title}}
		</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },  
  props: {  	
  	title: {
  		default: ''
  	},
  	hasIcon: {
  		default: true
  	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
$baseColor = #0099FA;
.title
	font-size: 16px;
	color: #1F2D3D;
	font-weight: 900;
	height: 20px;
	line-height: 20px;
	margin-bottom: 20px;
	.title_icon
		width: 4px;
		height: 16px;
		background-color: $baseColor;
		display: inline-block;
		margin-right: 10px;
		margin-top: 2px;
		float: left;
</style>
